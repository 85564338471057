@import '/styles/const.scss';

.container {
  background-color: $BACKGROUND_2;
  border-radius: 10px;
  max-width: 500px;
  margin: 10px auto 0;
  display: flex;
  flex-direction: column;
  transition: background-color $default-timing-duration $default-timing-function;

  & svg {
    transition: color $default-timing-duration $default-timing-function;
  }
}

.postHead {
  display: flex;
  align-items: center;
  padding: 8px 10px;
}

.username {
  flex: 1;
  margin-left: 10px;
}

.avatar {
  height: 25px;
  width: 25px;
  border-radius: 13px;
}

.assetsContainer {
  position: relative;
}

.assets {
  display: flex;
  overflow-x: auto;
  scroll-snap-type: x mandatory;
  height: 100%;
  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }
}

.asset {
  width: 100%;
  min-width: 100%;
  max-width: 100%;
  scroll-snap-align: center;
}

.carouselCounter {
  &Container {
    width: 500px;
    max-width: 100vw;
    pointer-events: none;
    touch-action: none;
  }

  position: absolute;
  top: 15px;
  right: 15px;
  z-index: 1;
  align-self: flex-end;
  background-color: rgba(0, 0, 0, 0.6);
  border-radius: 16px;
  width: auto;
  padding: 5px 8px;
  justify-content: center;
}

.descriptionContainer {
  transition: max-height $default-timing-duration $default-timing-function;
  background: none;
  border: none;
  overflow: hidden;
  position: relative;
  padding: 0;
}

.description {
  margin: 5px 10px;
  text-align: left;
}

.gradient {
  background: linear-gradient(0deg, $BACKGROUND_2, transparent);
  pointer-events: none;
  touch-action: none;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  transition: height $default-timing-duration $default-timing-function;
}

.openBody {
  transition: max-height $default-timing-duration $default-timing-function;
  background: none;
  border: none;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  padding: 0;
  cursor: pointer;
}

.readBody {
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-color: $BACKGROUND_3;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  transition: width $default-timing-duration $default-timing-function,
    background-color $default-timing-duration $default-timing-function;
}

.actionsContainer {
  display: flex;
  margin: 10px;
  align-items: flex-end;
}

.actions {
  display: flex;
  margin-right: 10px;
  flex: 1;
}

:export {
  TEXT: $TEXT;
  RED_COLOR: $red-color;
  TEXT_GRAY: $TEXT_GRAY;
}
