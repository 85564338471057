@import '/styles/const.scss';

@font-face {
  font-family: 'SFNumbers';
  src: url('/SFNumbers.woff2') format('woff2'), url('/SFNumbers.woff') format('woff');
}

.container {
  padding: 15px;
  display: flex;
  min-height: 100vh;
  max-width: 1400px;
  margin: 0 auto;
  align-items: center;
  user-select: none;

  @media screen and (max-width: 700px) {
    flex-direction: column-reverse;
  }
}

.content {
  margin-left: 50px;
  display: flex;
  flex-direction: column;

  @media screen and (max-width: 700px) {
    margin-left: 0;
    margin-bottom: 50px;
  }
}

.description {
  margin-top: 15px;
}

.image {
  pointer-events: none;
  touch-action: none;
  z-index: 10;
}

.phoneContainer {
  display: flex;
  flex-direction: column;
}

.phone {
  display: flex;
  flex-direction: column;
  background-color: $BACKGROUND_1;
  transition: background-color $default-timing-duration $default-timing-function;
  max-width: 437px;
  min-width: 437px;
  position: relative;
}

.postsGrid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 2px;
  background: none;
  padding: 0;
  margin: 0;
  border: none;
}

.posts {
  display: flex;
  flex-direction: column;
  padding: 0 10px;
}

.screen {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: $BACKGROUND_1;
  transition: background-color $default-timing-duration $default-timing-function,
    filter $default-timing-duration $default-timing-function;
}

.screen2 {
  transform: translate(100%);
  transition: transform $default-timing-duration $default-timing-function;
  z-index: 1;
}

.inner {
  position: absolute;
  left: 5%;
  right: 5%;
  top: 2%;
  bottom: 2%;
  overflow: hidden;
  border-radius: 20px;
}

.statusBar {
  display: flex;
  padding: 20px 32px 20px 50px;
  justify-content: space-between;
  align-items: center;
  color: $TEXT;
  transition: color $default-timing-duration $default-timing-function;
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 2;
}

.clock {
  font-family: SFNumbers, sans-serif;
  font-size: 16px;
  line-height: 22px;
  /* identical to box height, or 138% */

  text-align: center;
}

.header {
  display: flex;
  background-color: $BACKGROUND_2;
  transition: background-color $default-timing-duration $default-timing-function,
    border-color $default-timing-duration $default-timing-function;
  padding: 15px;
  border-bottom: solid 1px $BORDER;
  align-items: center;
  padding-top: 77px;
}

.back {
  margin: -15px;
  margin-right: 0;
  padding: 15px;
  background: none;
  border: none;
  color: $TEXT;
  transition: color $default-timing-duration $default-timing-function;
}

.footerContainer {
  max-width: 1400px;
  margin: 0 auto;
  padding: 15px;
  padding-top: 0;
}

.footer {
  display: flex;
  flex-wrap: wrap;
  color: $BLUE;
  margin-right: -15px;
  transition: color $default-timing-duration $default-timing-function;

  & > * {
    margin-right: 15px;
    margin-top: 10px;
  }
}

.cookie {
  align-items: center;
  margin-top: 20px;
  color: $TEXT_GRAY;
  transition: color $default-timing-duration $default-timing-function;
}

.logo {
  margin-bottom: 20px;

  & svg {
    color: $TEXT;
  }
}
