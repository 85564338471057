@import '/styles/const.scss';

.container {
  position: relative;
  overflow: hidden;
}

.thumbnail {
  filter: blur(10px);
  object-fit: fill;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.videoButton {
  background: none;
  border: none;
  padding: 0;
}

.videoContainer {
  object-fit: contain;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transition: opacity 200ms ease;
}

.overlay {
  position: relative;
  display: flex;
  z-index: 1;
  background: red;
  opacity: 0.7;
  margin-bottom: 50%;
}

.spinner {
  position: absolute;
  align-self: center;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.controlElement {
  border: none;
  background: #000000aa;
  padding: 4px 7px;
  border-radius: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: opacity $default-timing-duration $default-timing-function;

  &.hidden {
    opacity: 0;
  }
}

.image {
  object-fit: contain;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  transition: opacity 200ms ease;
}

.visible {
  opacity: 1;
}

.progressBar {
  z-index: 1;
  height: 40px;
  cursor: pointer;
  display: flex;
  align-items: flex-end;
  margin: 0px;
  transition: margin $default-timing-duration $default-timing-function;
  position: relative;

  & .thumb {
    width: 10px;
    height: 10px;
    background: $BLUE;
    border-radius: 50%;
    transform: translate(-50%, 50%);
    opacity: 0;
    transition: opacity $default-timing-duration $default-timing-function;

    &Container {
      pointer-events: none;
      touch-action: none;
      position: absolute;
      left: 0%;
      bottom: 0px;
      width: 100%;
      transform: translate(0%);
      transition: bottom $default-timing-duration $default-timing-function,
        transform $default-timing-duration $default-timing-function;
    }
  }

  & .all {
    pointer-events: none;
    touch-action: none;
    background: #8b8b8b50;
    width: 100%;
    overflow: hidden;
    border-radius: 2px;
    transition: margin $default-timing-duration $default-timing-function;
    opacity: 0.8;
  }

  & .past {
    pointer-events: none;
    touch-action: none;
    height: 3px;
    background: $BLUE;
    width: 100%;
    transition: transform $default-timing-duration $default-timing-function;
  }
}

.controls {
  display: flex;
  justify-content: space-between;
  margin: 0 15px -10px;
  transition: margin $default-timing-duration $default-timing-function;

  &Container {
    display: flex;
    flex-direction: column;
    position: absolute;
    padding-bottom: 0px;
    left: 0;
    bottom: 0;
    right: 0;

    &.paused,
    &:hover {
      & .progressBar {
        margin: 0 15px;

        & .all {
          margin-bottom: 15px;
          opacity: 1;
        }

        & .thumb {
          opacity: 1;

          &Container {
            bottom: 16px;
          }
        }
      }

      & .controls {
        margin-bottom: 0px;
      }
    }
  }
}
