@import '/styles/const.scss';

.main {
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 22px;
  color: $TEXT;
  transition: color $default-timing-duration $default-timing-function;

  & p {
    display: inline !important;
  }
}
