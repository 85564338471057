@import '/styles/const.scss';

.main {
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 16px;
  color: $TEXT;

  & p {
    display: inline !important;
  }
}
