@import '/styles/const.scss';

.main {
  background: $BACKGROUND_2;
  transition: background-color $default-timing-duration $default-timing-function;
  display: flex;
  flex-direction: column;
}

.avatarBlock {
  margin: 15px 15px 5px;
  display: flex;
  align-items: center;
}

.avatar {
  height: 80px;
  min-height: 80px;
  width: 80px;
  min-width: 80px;
  border-radius: 40px;
}

.textBlock {
  margin-left: 20px;
}

.textBlockSubtitle {
  margin-top: 5px;
  color: $TEXT_GRAY;
}

.name {
  margin: 10px 15px 0;
}

.bio {
  margin: 10px 15px 0;
}

.edit {
  margin: 15px;
}
