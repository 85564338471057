@import '/styles/const.scss';

.main {
  margin: 0 10px;
  padding: 7px 0;
  display: flex;
  align-items: center;
  height: 41px;
  border: none;
  border-top: solid 2px $BORDER;
  background: none;
  text-align: left;
  transition: border-color $default-timing-duration $default-timing-function;
}

.avatar {
  border-radius: 20px;
  margin-right: 10px;
}

:export {
  TEXT: $TEXT;
}
