@import '/styles/const.scss';

.main {
  position: relative;
  display: flex;
  min-height: 40px;
  padding: 8px 18px;
  border-radius: 5px;
  border: none;
  cursor: pointer;
  text-decoration: none;
  transition: background-color 100ms ease, color 100ms ease;
  background-color: $background-blue;
  color: $text-white;
  font-size: 18px;
  justify-content: center;

  &:disabled {
    background-color: $text-gray;
    cursor: default;
  }
}

.loading {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  height: 20px;
}

.round {
  width: 40px;
  min-width: 40px;
  max-width: 40px;
  padding: 0px;
  align-items: center;
  justify-content: center;

  & > svg {
    right: 10px;
  }
}
