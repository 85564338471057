@import '/styles/const.scss';

.main {
  display: flex;
  margin-right: 20px;
  padding: 3px 7px;
  border-radius: 25px;
  align-items: center;
  height: 27px;
  border: none;
}

:export {
  BLUE: $BLUE;
  BACKGROUND_3: $BACKGROUND_3;
  TEXT_WHITE: $TEXT_WHITE;
}
