@import '/styles/const.scss';

.container {
  position: relative;
  overflow: hidden;
}

.thumbnail {
  filter: blur(10px);
  object-fit: fill;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.image {
  object-fit: contain;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  transition: opacity 200ms ease;
}

.loaded {
  opacity: 1;
}
